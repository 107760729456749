import React from 'react';
import { Button, ButtonProps, Icon, IconProps } from '@chakra-ui/react';
import { useModalDispatch } from '@olo-web/client-state';
import { EAuthEntryPoints, EModalTypes } from '@olo-web/types/enums';
import { UserCircle } from '@olo-web/assets/icons/UserCircle.ui';

interface ILoginButton extends ButtonProps {
  iconProps?: IconProps;
}

export const LoginButton = (props: ILoginButton) => {
  const { iconProps = {}, ...rest } = props;
  const modalDispatch = useModalDispatch();

  const handleClick = (): void => {
    modalDispatch({
      type: 'OPEN_MODAL',
      payload: {
        modalKey: EModalTypes.LOGIN,
        analyticsAuthEntryPoint: EAuthEntryPoints.SIGN_IN_BUTTON,
      },
    });
  };

  return (
    <Button
      variant="ghost"
      rightIcon={<Icon as={UserCircle} h={5} w={5} {...iconProps} />}
      onClick={handleClick}
      colorScheme="blackAlpha"
      {...rest}
    >
      Sign in
    </Button>
  );
};
