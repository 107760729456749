import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const LoyaltyConfetti = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 26 56" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0664 17.884C14.1997 17.884 11.0664 21.0174 11.0664 24.884C11.0664 28.7507 14.1997 31.884 18.0664 31.884C21.9331 31.884 25.0664 28.7507 25.0664 24.884C25.0664 21.0174 21.9331 17.884 18.0664 17.884ZM18.0664 27.924C17.4651 27.924 16.8774 27.7457 16.3775 27.4117C15.8775 27.0777 15.4879 26.6029 15.2578 26.0474C15.0277 25.4919 14.9675 24.8807 15.0848 24.291C15.2021 23.7013 15.4917 23.1596 15.9168 22.7344C16.342 22.3093 16.8836 22.0197 17.4733 21.9024C18.063 21.7851 18.6743 21.8454 19.2298 22.0754C19.7853 22.3055 20.26 22.6952 20.5941 23.1951C20.9281 23.695 21.1064 24.2828 21.1064 24.884C21.1066 25.2833 21.0281 25.6787 20.8754 26.0476C20.7226 26.4165 20.4987 26.7517 20.2164 27.034C19.9341 27.3164 19.5989 27.5403 19.23 27.693C18.8611 27.8457 18.4657 27.9242 18.0664 27.924Z"
        fill="currentColor"
      />
      <path
        opacity="0.3"
        d="M0 14.1101L1.33401 12.9114L1.71836 11.1712L2.93235 12.4885L4.69466 12.868L3.36063 14.0667L2.9763 15.8069L1.76229 14.4896L0 14.1101Z"
        fill="currentColor"
      />
      <path
        d="M4.57031 51.6555L7.31952 49.4033L8.23961 45.995L10.5205 48.7097L13.9722 49.6182L11.2229 51.8704L10.3029 55.2787L8.02197 52.564L4.57031 51.6555Z"
        fill="currentColor"
      />
      <ellipse cx="22.937" cy="6.75749" rx="1.36869" ry="1.35149" fill="currentColor" />
      <ellipse
        opacity="0.8"
        cx="7.88161"
        cy="0.675744"
        rx="0.684343"
        ry="0.675744"
        fill="currentColor"
      />
      <ellipse
        opacity="0.6"
        cx="1.7222"
        cy="33.7873"
        rx="1.36869"
        ry="1.35149"
        fill="currentColor"
      />
    </Icon>
  );
};
