import React from 'react';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { Button, ButtonProps, Icon, Box, Text, Flex } from '@chakra-ui/react';
import { ChevronRight, Edit, Plus } from 'react-feather';
import { useModalDispatch } from '@olo-web/client-state';
import { EModalTypes } from '@olo-web/types/enums';
import { LoyaltyConfetti } from '@olo-web/assets/icons/LoyaltyConfetti.ui';
import { useLoyalty } from '@olo-web/domain/customer/hooks/useLoyalty';
import { useOffersHelpers } from '@olo-web/components/organisms/Modals/ApplyOffersModal/hooks/useOffersHelpers';
import { useIsCheckoutPage } from '@olo-web/utils/common/hooks';
import { isOfferAPromo, isOfferAReward } from '@olo-web/utils/common/functions/index';
import { isOfferADeal } from '@olo-web/utils/common/functions/isOfferADeal';
import { useIsDineIn } from '@olo-web/utils/common/hooks/useIsDineIn';
import { useIsScanAndPayPage } from '@olo-web/utils/common/hooks/useIsScanAndPayPage';
const commonStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  w: '100%',
  p: 0,
  _hover: {
    bg: 'blackAlpha.50',
  },
  _active: {
    bg: 'blackAlpha.100',
  },
};

interface IApplyOffersButton extends ButtonProps {
  handleOpenBagDrawer?: () => void;
}

export const ApplyOffersButton = (props?: IApplyOffersButton) => {
  const { data: loyalty } = useLoyalty();
  const { data: order } = useOrder();
  const { data: merchant } = useMerchant();
  const modalDispatch = useModalDispatch();
  const isCheckoutPage = useIsCheckoutPage();
  const { availableOffers } = useOffersHelpers();
  const hasPromoAdded = isOfferAPromo(order?.discounts?.[0]);
  const hasRewardAdded = isOfferAReward(order?.discounts?.[0]);
  const hasDealAdded = isOfferADeal(order?.discounts?.[0]);
  const hasOfferAdded = hasPromoAdded || hasRewardAdded;
  const hasSpots = Math.floor(parseInt(loyalty?.availableSpots, 10)) !== 0;
  const hasAvailableOffers = availableOffers?.length;
  const merchantLoyaltyEnabled = merchant?.spotOnLoyaltyEnabled;
  const isDineIn = useIsDineIn();
  const isScanAndPay = useIsScanAndPayPage();

  const canApplyReward = merchantLoyaltyEnabled && hasAvailableOffers && hasSpots && !hasOfferAdded;

  const handleClick = () => {
    modalDispatch({
      type: 'OPEN_MODAL',
      payload: {
        modalKey: EModalTypes.APPLY_OFFERS,
        // TODO: After moving a <BagButtonAndDrawer> to a modal (with modalClientState), the modalContext handleOpenBagDrawer should be removed from all places of use
        modalContext: {
          handleOpenBagDrawer: props?.handleOpenBagDrawer,
        },
      },
    });
  };

  let label = '';
  if (hasOfferAdded) {
    label = 'Edit ';

    if (hasDealAdded) {
      label += 'promotion';
    } else if (hasRewardAdded) {
      label += 'reward';
    } else {
      label += 'promotion';
    }
  } else {
    label = 'Apply a ';
    if (merchantLoyaltyEnabled) {
      label += 'reward or promotion';
    } else {
      label += 'promotion';
    }
  }
  if (canApplyReward) {
    return (
      <Button
        variant="outline"
        colorScheme="blackAlpha"
        {...commonStyles}
        bg="transparent"
        borderColor="blackAlpha.100"
        overflow="hidden"
        h="69px"
        onClick={handleClick}
        borderRadius="4px"
        {...props}
        my={0}
      >
        <Flex align="stretch" h="100%">
          <Box h="100%" w={2} bg="loyalty.300" />
          <LoyaltyConfetti color="loyalty.300" h="100%" w="36px" />
          <Flex flexDir="column" align="flex-start" ml={4}>
            <Text textStyle="pBold" color="blackAlpha.800" mt={3} lineHeight="21px">
              You have a reward available!
            </Text>
            <Text
              textStyle="label"
              color="blackAlpha.800"
              mb={3}
              mt={1}
              fontSize="0.8125rem"
              lineHeight="19.5px"
            >
              {label}
            </Text>
          </Flex>
        </Flex>
        <Icon as={ChevronRight} h={6} w={6} color="blackAlpha.800" mr="7px" />
      </Button>
    );
  }

  const showApplyRewardOrPromoButton = !isCheckoutPage || isDineIn || isScanAndPay;

  if (showApplyRewardOrPromoButton) {
    return (
      <Button
        variant="tertiary"
        onClick={handleClick}
        {...commonStyles}
        colorScheme="primary"
        color="primary.350"
        rightIcon={
          <Icon
            as={hasOfferAdded ? Edit : Plus}
            h={hasOfferAdded ? 5 : 6}
            w={hasOfferAdded ? 5 : 6}
            strokeWidth={2}
          />
        }
        {...(props || {})}
        data-testid={hasOfferAdded ? 'edit-offer-button' : 'add-offer-button'}
      >
        {label}
      </Button>
    );
  }

  return null;
};
