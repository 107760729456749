import React from 'react';
import { chakra, forwardRef, ChakraProps, ComponentWithAs } from '@chakra-ui/react';
import { motion, isValidMotionProp, MotionProps } from 'framer-motion';

export type MotionBoxProps = Omit<ChakraProps, keyof MotionProps> &
  MotionProps & {
    as?: React.ElementType;
  };

export const MotionBox = motion(
  forwardRef<MotionBoxProps, 'div'>((props, ref) => {
    const chakraProps = Object.fromEntries(
      // do not pass framer props to DOM element
      Object.entries(props).filter(([key]) => !isValidMotionProp(key))
    );
    return <chakra.div ref={ref} {...chakraProps} />;
  })
) as ComponentWithAs<'div', MotionBoxProps>;
