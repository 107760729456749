import { useQuery, UseQueryResult } from 'react-query';
import axios, { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import { serializeToCamelCase } from '@olo-web/utils/common/functions';
import { IMarketingDealsResponse } from '@olo-web/types/marketingDeals.interface';
import { useCustomer } from './useCustomer';

export const getCustomerMarketingDeals = async (
  customerId: number,
  merchantId: string
): Promise<IMarketingDealsResponse> => {
  try {
    const url = `/api/marketing-deals`;
    const body = { customer_id: customerId, merchant_id: merchantId };
    const { data } = await axios.get(url, { params: body });
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

export const useCustomerMarketingDeals = (): UseQueryResult<
  IMarketingDealsResponse,
  AxiosError
> => {
  const router = useRouter();
  const customer = useCustomer();
  const customerId = customer?.data?.customerIdInt;
  const { merchantId } = router.query;
  return useQuery(
    ['marketingDeals', merchantId, customerId],
    () => getCustomerMarketingDeals(customerId, merchantId as string),
    {
      staleTime: 1000000,
      select: serializeToCamelCase,
      retry: 0,
      enabled: !!customerId && !!merchantId,
      retryOnMount: false,
    }
  );
};
