import { useMemo } from 'react';
import { useCheckoutDispatch, useCheckoutState, useModalDispatch } from '@olo-web/client-state';
import { EAuthEntryPoints, EModalTypes } from '@olo-web/types/enums';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { uniqByProp } from '@organisms/Modals/ApplyOffersModal/functions/uniqByProp';
import { useLoyalty } from '@olo-web/domain/customer/hooks/useLoyalty';
import { useCustomerState } from '@olo-web/client-state';
import {
  IAvailableDeal,
  IAvailableReward,
  ILoyaltyItem,
} from '@olo-web/types/loyaltyReward.interface';
import { useCustomerMarketingDeals } from '@olo-web/domain/customer/queries/useCustomerMarketingDeals';
import { DiscountAdjustmentKinds } from '@olo-web/utils/common/functions/getDealDiscountDescription';
import {
  TCashDiscountConfig,
  TPercentDiscountConfig,
} from '@olo-web/types/marketingDeals.interface';

export const useOffersHelpers = () => {
  const modalDispatch = useModalDispatch();
  const { data: loyalty, isLoading: loyaltyIsLoading } = useLoyalty();
  const { data: marketingDeals } = useCustomerMarketingDeals();
  const { data: order, isLoading: orderIsLoading } = useOrder();
  const { data: merchant } = useMerchant();
  const { selectedOffer } = useCheckoutState();
  const checkoutDispatch = useCheckoutDispatch();
  const customerState = useCustomerState();

  const itemTotal: number = useMemo(() => {
    if (!order) {
      return 0;
    }

    const totalSurchargeable: number =
      order?.surcharges
        ?.filter((s) => s.taxable)
        .reduce((acc, curr) => acc + Number(curr.displayAmount), 0) || 0;

    const preDiscountSubtotal: number = order?.preDiscountSubtotal
      ? Number(order?.preDiscountSubtotal)
      : 0;

    return preDiscountSubtotal - totalSurchargeable;
  }, [order]);

  const handleOfferSelection = (offer) => {
    checkoutDispatch({
      type: 'SET_SELECTED_OFFER',
      payload: offer,
    });
  };

  const handleSignInClick = () => {
    modalDispatch({
      type: 'OPEN_MODAL',
      payload: {
        modalKey: EModalTypes.LOGIN,
        analyticsAuthEntryPoint: EAuthEntryPoints.APPLY_OFFERS_MODAL,
      },
    });
  };

  const allRewards = useMemo(
    () => (merchant?.spotOnLoyaltyEnabled && loyalty?.rewards) || {},
    [merchant?.spotOnLoyaltyEnabled, loyalty?.rewards]
  );
  const allDeals = useMemo(() => loyalty?.deals || {}, [loyalty?.deals]);

  const allUCDMarketingDeals = useMemo(
    () => marketingDeals?.uniqueCodeDeals || [],
    [marketingDeals?.uniqueCodeDeals]
  );

  const allAdHocDeals = useMemo(
    () => marketingDeals?.adHocCodeDeals || [],
    [marketingDeals?.adHocCodeDeals]
  );

  const availableOffers = useMemo(() => {
    const availablePromocodes = Object.entries(customerState?.promos).reduce(
      (acc: any, [key, value]: [string, any]) => {
        const isAutoApplyMarketingCodeDeal = value?.minOrderValue && value.promotionType === 1;
        if (value?.isActive || isAutoApplyMarketingCodeDeal) {
          acc.push({
            ...value,
            isActive: isAutoApplyMarketingCodeDeal
              ? itemTotal >= Number(value.minOrderValue)
              : value.isActive,
            promoCode: key,
            promotionId: value.marketingPromotionId || value.promotionId,
            tempPromotionId: value.promotionId,
          });
        }

        return acc;
      },
      []
    );

    const availableUCDMarketingDeals = allUCDMarketingDeals
      .filter((d) => d.status === 'active')
      .map(
        (curr) => ({
          promotionType: 1,
          promotionName: curr.deal.name,
          promotionId: curr.id,
          promoCode: curr.value,
          isActive:
            'minOrderValue' in curr.deal.discountConfig
              ? curr.deal.discountConfig.minOrderValue <= itemTotal
              : true,
          promotionEndAt: curr.deal.endDatetime,
          adjustmentKind:
            curr.deal.discountType === 'percent'
              ? DiscountAdjustmentKinds.DiscountAdjustmentKindPercentOff
              : DiscountAdjustmentKinds.DiscountAdjustmentKindAmountOff,
          adjustmentValue:
            (curr.deal.discountConfig as TCashDiscountConfig).value ||
            (curr.deal.discountConfig as TPercentDiscountConfig).percent,
          minOrderValue:
            'minOrderValue' in curr.deal.discountConfig
              ? curr.deal.discountConfig.minOrderValue
              : undefined,
          maxDiscountValue:
            'maxDiscountValue' in curr.deal.discountConfig
              ? curr.deal.discountConfig.maxDiscountValue
              : undefined,
        }),
        []
      );

    const availableAdHocDeals = allAdHocDeals
      .filter((d) => d.status === 'active')
      .map(
        (curr) => ({
          promotionType: 1,
          promotionName: curr.deal.name,
          promotionId: curr.id,
          promoCode: curr.value,
          isActive: true,
          promotionEndAt: curr.endDatetime,
          adjustmentKind:
            curr.deal.discountType === 'percent'
              ? DiscountAdjustmentKinds.DiscountAdjustmentKindPercentOff
              : DiscountAdjustmentKinds.DiscountAdjustmentKindAmountOff,
          adjustmentValue:
            curr.deal.discountType === 'percent'
              ? (curr.deal.discountConfig as TPercentDiscountConfig).percent
              : (curr.deal.discountConfig as TCashDiscountConfig).value,
          isAdHocDeal: true,
        }),
        []
      );

    const availableRewards = Object.values(allRewards).reduce(
      (accRewards: IAvailableReward[], reward: ILoyaltyItem) => {
        if (parseInt(loyalty?.availableSpots || '') >= reward.spotsToRedeem) {
          accRewards.push({
            promotionType: reward.promotionType,
            promotionName: reward.promotionName,
            promotionId: reward.promotionId.toString(),
            discountValue: reward.discountValue,
            spotsToRedeem: reward.spotsToRedeem,
          });
        }

        return accRewards;
      },
      []
    );

    const availableDeals = Object.values(allDeals).reduce(
      (accDeals: IAvailableDeal[], deal: ILoyaltyItem) => {
        if (Number(deal?.minPurchaseAmount) <= Number(order?.preDiscountSubtotal)) {
          accDeals.push({
            promotionType: deal.promotionType,
            promotionName: deal.promotionName,
            promotionId: deal.promotionId.toString(),
            discountValue: deal.discountValue,
            minPurchaseAmount: deal.minPurchaseAmount,
            promotionEndAt: deal.promotionEndAt,
          });
        }

        return accDeals;
      },
      []
    );

    return uniqByProp(
      [
        ...availableRewards,
        ...availablePromocodes,
        ...availableDeals,
        ...availableUCDMarketingDeals,
        ...availableAdHocDeals,
      ],
      'promotionId'
    );
  }, [
    loyalty,
    customerState?.promos,
    order?.preDiscountSubtotal,
    allRewards,
    allDeals,
    allUCDMarketingDeals,
    allAdHocDeals,
  ]);

  const unavailableOffers = useMemo(() => {
    const unavailablePromoCodes =
      Object.values(customerState?.promos)?.filter(
        (promo: any) =>
          !promo?.isActive || (promo?.minOrderValue && itemTotal < promo.minOrderValue)
      ) || [];
    const unavailableRewards = Object.values(allRewards).filter(
      (item) => parseInt(loyalty?.availableSpots || '') < item.spotsToRedeem
    );

    const unavailableDeals = Object.values(allDeals).filter(
      (deal) => Number(deal?.minPurchaseAmount) > Number(order?.preDiscountSubtotal)
    );

    return [...unavailableRewards, ...unavailablePromoCodes, ...unavailableDeals];
  }, [loyalty, customerState?.promos, order?.preDiscountSubtotal, allRewards, allDeals]);

  const appliedOffer = useMemo(() => {
    return order?.discounts?.[0];
  }, [order?.discounts]);

  return {
    selectedOffer,
    handleSignInClick,
    handleOfferSelection,
    availableOffers,
    unavailableOffers,
    appliedOffer,
    order,
    loyalty,
    loyaltyIsLoading,
    orderIsLoading,
    itemTotal,
  };
};
